import frame from "../../images/frame.png";
import abouthomebanner from "../../images/aboutbanner.png";
import { apiurl } from "../../url";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
const Abouthome = () => {
  const [about, setAbout] = useState(null);
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  const navigate = useNavigate();
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const request = new Request(
      `${apiurl}/api/about?title=About`,
      requestOptions
    );

    fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        setAbout(result);
     
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const aboutpage=()=>{
    if(logincheck.id !== null && logincheck.name !== null ){
      navigate('/about-us')
    }else{
      navigate('/signup')
    }
   

  }
  return (
    <>
      <div className="abouhome_container" id="about-us">
        <div className="abouthomemain-container">
          <div className="abouthomeimage">
            <div className="abouthomeimagebox">
              <img src={abouthomebanner} alt="about image" />
              <button onClick={aboutpage}>
                {" "}
                <img src={frame} alt="frame" />
                <p>{about?.about?.subtitle}</p>
              </button>
            </div>
          </div>
          <div className="abouthometext">
            <h1 className="abouthome_heading"id="about-continer">{about?.about?.title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  about && about.about && about.about.details
                    ? about.about.details
                    : "",
              }}
            />
            {/* <Link to="/about-us">Read more</Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Abouthome;
