import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { apiurl } from "../url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const Bookinghistory = () => {
  const [bookedpropertydetail, Setbookedpropertydeatil] = useState([]);
  const navigate = useNavigate();
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  useEffect(() => {
    if (logincheck.id != null) {
      const userId = logincheck.id;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      fetch(`${apiurl}/api/book?user_id=${userId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.errors) {
            Setbookedpropertydeatil("[]");
          } else {
            Setbookedpropertydeatil(result.data);
          }
        })
        .catch((error) => {
          toast.error(error.errors, { autoClose: 1000 });
        });
    }
  }, []);

  const navigatetoProperty=(item)=>{
navigate(`/property-details/${item.id}`)

  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="header2">
          <h1>Booked Property</h1>
          <div className="booked-container">
    
            {bookedpropertydetail != null &&
            bookedpropertydetail?.length !== 0 ? (
              bookedpropertydetail.map((data, index) => {
                if (data != null) {
                  return (
                    <div className="outerboxfeatured_image" key={index}>
                      <div className="propertyfeatured_image">
                        <img
                          src={`${apiurl}/img/property/main-img/${data?.main_img}`}
                          alt={`propertyimage ${index}`}
                        />
                      </div>
                      <div className="aboutdetails-box2">
                        <h2 onClick={()=>navigatetoProperty(data)}>{data?.title}</h2>
                        <h3>£{data?.price}</h3>
                        <p className="bookedtext">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 384 512"
                          >
                            <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                          </svg>
                          {data?.location}
                        </p>
                      </div>
                    </div>
                  );
                }
                return null;
              })
            ) : (
              <div className="photo-text booked">No Booked property</div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Bookinghistory;
