import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { apiurl } from "../url";
import { Link } from "react-router-dom";

const Properties = () => {
  const [propertydata, Setpropertydata] = useState([]);
  const [loader, Setloader] = useState(true);
  const locationParam = decodeURIComponent(
    window.location.search.split("=")[1]
  );

  useEffect(() => {
    if (window.location.search.split("=")[1]) {
      fetch(apiurl + "/api/search?location=" + locationParam)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          Setpropertydata(result.search);
          setTimeout(function () {
            Setloader(false);
          }, 1000);
        })
        .catch((error) => {});
    } else {
      fetch(apiurl + "/api/property")
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          Setpropertydata(result.property);
          setTimeout(function () {
            Setloader(false);
          }, 1000);
        })
        .catch((error) => {});
    }
  }, []);
  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          <Header />
          <div className="featuredproperty1_conatiner">
            <div className="main-container">
              <h1 className="property_heading1">All Properties</h1>
              <div className="featuredproperty1_imagebox">
                <div className="rowfeaturedproperty">
                  {propertydata != null && propertydata.length > 0 ? (
                    propertydata.map((item, index) => (
                      <div key={index} className="outerboxfeatured_image">
                        <div className="propertyfeatured_image">
                          <img
                            src={
                              apiurl + "/img/property/main-img/" + item.main_img
                            }
                            alt="propertyimage"
                          />
                          <button>
                            <Link to={`/property-details/${item.id}`}>
                              View More
                            </Link>
                          </button>
                        </div>
                        <p>{item.title}</p>
                      </div>
                    ))
                  ) : (
                    <h2 className="property_headingnotfound">
                      No Property Found
                    </h2>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Properties;
