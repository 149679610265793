import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { apiurl } from "../url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Savedproperty = () => {
  const [propertydetail, Setpropertydeatil] = useState([]);
  const [loader, Setloader] = useState(true);
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  useEffect(() => {
    const apiUrl = `${apiurl}/api/joinnow`;
    if (logincheck.id != null) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      fetch(`${apiUrl}?user_id=${logincheck.id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          Setpropertydeatil(result.property);
          Setloader(false);
        })
        .catch((error) => {
          toast.error(error.errors, { autoClose: 1000 });
        });
    }
  }, []);

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="saved-propertycontainer">
          <Header />
          <div className="container">
            <div className="header2">
              <h1>Favorite Properties</h1>
              {propertydetail?.length !== 0 ? (
                propertydetail.map((data, index) => {
                  if (data != null) {
                    return (
                      <div className="photo-text" key={index}>
                        <div className="photo">
                          <img
                            src={`${apiurl}/img/property/main-img/${data?.main_img}`}
                            alt={`propertyimage ${index}`}
                          />
                        </div>
                        <div className="aboutdetails-box2">
                          <h2>{data?.title}</h2>
                          <h3>£{data?.price}</h3>
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 384 512"
                            >
                              <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            {data?.location}
                          </p>
                          <Link to={`/property-details/${data.id}`}>
                            View More
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <div className="photo-text">No added property</div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Savedproperty;
