import { Link } from "react-router-dom";
import logo from "../images/vacaydroplogo.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import tiktok from "../images/tiktok.png";

import { apiurl } from "../url";
import { useEffect, useState } from "react";

const Footer = () => {
  const [footer, setfooter] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const request = new Request(
      `${apiurl}/api/footer?title=Footer`,
      requestOptions
    );

    fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        setfooter(result);
      })
      .catch((error) => {});
  }, []);
  const handleaboutclick = () => {
    let aboutContainer = document?.querySelector("#about-continer");

    if (aboutContainer) {
      aboutContainer.scrollIntoView({ behavior: "smooth" });
    } else {
    }
  };
  useEffect(() => {
    if (window.location.hash === "#about-us") {
      handleaboutclick();
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [window.location]);

  return (
    <>
      <div className="footer-container">
        <div className="container">
          <div className="row">
            <div className="footer-col1">
              <img src={logo} alt="vacaydroplogo" title="vacaydroplogo" />
              <p
  dangerouslySetInnerHTML={{
    __html: footer?.blog?.details ? footer?.blog?.details : "",
  }}
  style={{ listStyleType: "none", margin: 0, padding: 0 }}
></p>

            </div>
            <div className="footer-col">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  {window.location.pathname === "/" ? (
                    <Link onClick={handleaboutclick}>About us</Link>
                  ) : (
                    <Link to="/#about-us">About us</Link>
                  )}
                </li>
                <li>
                  <Link to="/properties">Properties</Link>
                </li>
                <li>
                  <Link to="/Howitworks">How it works</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Other Links</h4>
              <ul>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/term-and-conditions">Terms & conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/Faq">FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col4">
              <h4>Social Connect </h4>
              <div className="social-links">
                <a
                  href="
https://www.facebook.com/profile.php?id=61557009320388"
                  target="_blank"
                >
                  <img src={facebook} alt="facebook logo" title="facebook " />
                </a>
                <a href="https://www.instagram.com/vacaydrop/" target="_blank">
                  <img
                    src={instagram}
                    alt="instagram logo"
                    title="instagram "
                  />
                </a>
                <a href="https://twitter.com/VacayDrop" target="_blank">
                  <img src={twitter} alt="twitter logo" title="twitter " />
                </a>
                <a
                  href="
https://www.tiktok.com/@vacaydrop"
                  target="_blank"
                >
                  <img src={tiktok} alt="twitter logo" title="tiktok " />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightfooter">
        <div className="rightfooter_container">
          <h1 className="right-h1">
            {" "}
            © 2024 Vacay Drop, All Rights Reserved.{" "}
          </h1>
        </div>
      </div>
    </>
  );
};

export default Footer;
