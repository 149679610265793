import React from "react";
import sw from "../../images/sw.png";
import swalert from "../../images/swalert.png";
import swhome from "../../images/swhome.png";

const Sitework = () => {
  return (
    <>
         <div className="home-searchbox3">
      <div className="site-workheading">
        <h3>Get VacayDrops in 3 Easy Steps</h3>
      </div>
      <div className="site-workouter">
      <div className="site-workiconbox">
        <div className="site-workiconboximg">
          <img src={swalert} alt="swalert" title="" />
        </div>
        <div className="site-workiconboxtext">
          <h4>Join now for free and get last minute vacation options via text</h4>
        </div>
      </div>
      <div className="site-workiconbox">
        <div className="site-workiconboximg">
          <img src={sw} alt="sw" title="" />
        </div>
        <div className="site-workiconboxtext">
          <h4>
          If interested in a “drop”, just reply “book it” and we will reserve the property for you
          </h4>
        </div>
      </div>{" "}
      <div className="site-workiconbox">
        <div className="site-workiconboximg">
          <img src={swhome} alt="swhome" title="" />
        </div>
        <div className="site-workiconboxtext">
          <h4>Enjoy your last minute vacation for an amazing price</h4>
        </div>
      </div>
      </div>
    
      </div>
    </>
  );
};

export default Sitework;
