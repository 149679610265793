import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import userimage from "../images/userimage.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { apiurl } from "../url";

const Profile = () => {
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  const [loader, Setloader] = useState(false);
  const [errors, setErrors] = useState({});
  const [profile, setProfile] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile_no: "",
  });
  const [stateprofile, SetStateprofile] = useState(true);
  useEffect(() => {
    if (stateprofile === true && logincheck.id != null) {
      const id = logincheck.id;
      fetch(`${apiurl}/api/profile?user_id=${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
          setProfile(result.data);
          SetStateprofile(false);
        })
        .catch((error) => {
          // console.error("Error fetching profile:", error);
        });
    }
  }, [stateprofile, logincheck.id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "fname" || name === "lname") {
      if (!/^[a-zA-Z]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only characters are allowed" });
        return;
   
      }
    }
    if (name === "mobile_no") {
      if (!/^\d*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Numbers are allowed" });
        return;
      }
      if (value.length > 13) {
        setErrors({ ...errors, [name]: "Maximum 13 digit Phone no is allowed" });
        return;
      }
    }
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleEnquiry = (e) => {
    e.preventDefault();
    // console.log(profile)
    const emailRegex = /^[a-zA-Z0-9.]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$/;
    if (profile.fname === "") {
      toast.error("First name is required", { autoClose: 1000 });
    } else if (profile.lname === "") {
      toast.error("Last name is required", { autoClose: 1000 });
    } else if (profile.mobile_no === "") {
      toast.error("Mobile number is required", { autoClose: 1000 });
    } else if (profile.mobile_no.length < 10) {
      toast.error("Mobile number must be at least 10 digits", { autoClose: 1000 });
    }  else  if (profile.email && !emailRegex.test(profile.email)) {
      toast.error("Please enter valid email address.", { autoClose: 1000 });

    } else {

      Setloader(true);
  
      var requestOptions = {
        method: "POST",
        // body: formdata,
        redirect: "follow",
      };
  
      fetch(
        apiurl +
          "/api/profile/update?user_id=" +
          logincheck.id +
          "&fname=" +
          profile.fname +
          "&lname=" +
          profile.lname +
          "&email=" +
          profile.email +
          "&mobile_no=" +
          profile.mobile_no,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
           
          }
          return response.json();
        })
        .then((result) => {
          if (result.errors) {
            toast.error(result.errors, { autoClose: 1000 });
          } else {
            toast.success("Profile Update successfully", { autoClose: 1000 });
            Setloader(false);
            localStorage.setItem(
              "namevacay",
              profile.fname + " " + profile.lname
            );
          }
        })
        .catch((error) => {
          toast.error("Profile update failed", { autoClose: 1000 });
        });
    }
  };
  
  return (
    <>


      <div className="profile-container">
        <div className="profilemaincontainer">
        
          <Header />
          <div className="profileuserbox">
            <div className="profileheading-box">
              <h2>Account</h2>
            </div>
            <div className="profileuserimagebox">
              <img src={userimage} alt="userimage" />
            </div>
            <div className="profileuserdeatilsbox">
              <div className="profiledetailsboxhalf">
                <label>First Name <span>*</span></label>
                <input type="text" className="form-control set-control" id="first-name" placeholder="First Name" name="fname" value={profile.fname} onChange={handleChange}></input>
            
              </div>
              <div className="profiledetailsboxhalf">
                <label>
                  Last Name <span>*</span>
                </label>

                <input
                  type="text"
                  className="form-control set-control"
                  id="last-name"
                  placeholder="Last name"
                  name="lname"
                  value={profile.lname}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="profileuserdeatilsbox">
              <div className="profiledetailsboxhalf">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control set-control"
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  value={profile.email}
                  onChange={handleChange}
                ></input>
              </div>
              <div className="profiledetailsboxhalf">
                <label>
                  Mobile Number <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control set-control"
                  id="mobile-number"
                  placeholder="Mobile Number"
                  name="mobile_no"
                  value={profile.mobile_no}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            <div className="profilebutonupdatebox">
              <button
                className="profiledteailsupdatebtn"
                onClick={handleEnquiry}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />

    </>
  );
};
export default Profile;
