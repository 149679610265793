import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import frame from "../images/frame.png";
import Footer from "../Common/Footer";
import abouthomebanner from "../images/aboutbanner.png";
import { apiurl } from "../url";

const About = () => {
  const [about, setAbout] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const request = new Request(
      `${apiurl}/api/about?title=About`,
      requestOptions
    );

    fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        setAbout(result);
        // console.log(result);
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <div className="about-contianer">
        <Header />
        <div className="aboutcomponent">
          <div className="abouhome_container">
            <div className="abouthomemain-container">
              <div className="abouthomeimage">
                <div className="abouthomeimagebox">
                  <img src={abouthomebanner} alt="about" />
                  <button>
                    {" "}
                    <img src={frame} alt="frame" />
                    <p>{about?.about?.subtitle}</p>
                  </button>
                </div>
              </div>
              <div className="abouthometext">
                <h1 className="abouthome_heading">{about?.about?.title}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      about && about.about && about.about.details
                        ? about.about.details
                        : "",
                  }}
                />

                {/* <button>Read more</button> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default About;
