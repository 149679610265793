import arrow from "../../images/arrow-right.png";
import arrowleft from '../../images/arrow-left.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef, useState } from "react";
import { apiurl } from "../../url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Nearbyproperties = () => {
  const sliderRef = useRef();
  const [propertydetail, Setpropertydeatil] = useState([]);
  const [id, setid] = useState(localStorage.getItem("vacayid") ? localStorage.getItem("vacayid") : "")
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${apiurl}/api/property/lastmint`)
      .then((response) => response.json())
      .then((result) => {
        Setpropertydeatil(result.data)
        // console.log(result.data);
      })
      .catch((error) => { toast.error(error.errors, { autoClose: 1000 }); });
  }, []);


  const join_now = (e) => {
    if (id === "") {
      navigate("/signup");
    } else {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };
      fetch(apiurl + "/api/join-now?user_id=" + id + "&propertyId=" + e, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
      
          if (result.error) {
            toast.error(result.error, { autoClose: 1000 });
          } else {
          toast.success("Successfully Join!", { autoClose: 1000 });}
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
           toast.error(error.errors, { autoClose: 1000 }); 
          });
    }
  };


  var settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },

 
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
    
      {
        propertydetail &&propertydetail.length > 2 ?

          <div className="nearbyproperty_maincontainer" id="lastminuteproperty">
            <div className="properties_conatiner">
              <h1 className="property_heading">Last Minute Properties</h1>

              <div className="properties_imagebox">
                {
                  propertydetail.length > 2 ?
                    <div className="arrow-previous">
                      <button className="previous_arrow">
                        <img src={arrowleft} alt="arrow" title="next arrow" />
                      </button>
                    </div> : ""
                }
                {
                  propertydetail.length > 2 ?

                    <Slider
                      ref={sliderRef}
                      {...settings2}
                      id="Slider-4"
                      className="slider_test"
                    >
                      {propertydetail?.slice()
                    .sort(
                      (a, b) =>
                        new Date(b.updated_at) - new Date(a.updated_at)
                    ).map((data, index) => {
                      let buttonText;
                      if (index % 3 === 0) {
                        buttonText = "Join Now";
                      } else if (index % 3 === 1) {
                        buttonText = "Get Drops";
                      } else {
                        buttonText = "Learn More";
                      }
                        return <div className="item" key={index}>
                          <div className="outerbox_image">
                            <div className="property_image">
                              <img src={`${apiurl}/img/property/main-img/${data?.main_img}`} alt="propertyimage" />
                              <button >
                              <Link to={`/property-details/${data?.id}`}>{buttonText}</Link>
                                </button>
                            </div>
                            <p> {data?.title} </p>
                          </div>
                        </div>
                      })
                      }
                    </Slider>
                    :
                    propertydetail
                    .slice()
                    .sort(
                      (a, b) =>
                        new Date(b.updated_at) - new Date(a.updated_at)
                    )?.map((data, index) => {
                      return <div className="outerbox_image1" key={index}>
                        <div className="property_image">
                          <img src={`${apiurl}/img/property/main-img/${data?.main_img}`} alt="propertyimage" />
                          <button >
                          <Link to={`/property-details/${data?.id}`}>Join now</Link>
                             </button>
                        </div>
                        <p> {data?.title} </p>
                      </div>
                    })
                }
                {
                  propertydetail.length > 2 ?
                    <div className="arrow-previous">
                      <button className="next_arrow">
                        <img src={arrow} alt="arrow" title="next arrow" />
                      </button>
                    </div > : ""
                }
              </div>
            </div>
          </div>
          : ""
      }
    </>
  );
};

      export default Nearbyproperties;
