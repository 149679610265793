import React, { useState, useRef } from "react";
import { apiurl } from "../url";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [showModal, setShowModal] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, Setloader] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [newphoneNumber, setnewPhoneNumber] = useState("");
  const refs = [useRef(), useRef(), useRef(), useRef()];
  const [code, Setcode] = useState("");
  const [errors, setErrors] = useState({});
  const [userid, SetUserid] = useState("");
  const navigate = useNavigate();
  const hnadleInputChangelogin = (e) => {
    // console.log("hello");
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "phoneNumber") {

      if (!/^\d*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Numbers are allowed" });
        return;
      }

      if (value.length > 13) {
        setErrors({ ...errors, [name]: "Maximum 13 digit Phone no is allowed" });
        return;
      }
    }

    if (name === "phoneNumber") {
      setPhoneNumber(value);
    }
  };
  const handleInputClick = (name) => {
    setErrors({ ...errors, [name]: "" });
  };
  const handleotpChange = (index, e) => {
    const { value } = e.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;

      if (value && index < 3) {
        refs[index + 1].current.focus();
      }

      if (!value && index > 0) {
        refs[index - 1].current.focus();
      }

      return newOtp;
    });
  };
  const handleotplogin = (e) => {
    e.preventDefault();
    const otpString = otp.join("");
    var formdata = new FormData();

    formdata.append("mobile_no","+1" + phoneNumber);
    
    formdata.append("otp", +otpString);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    fetch(apiurl + "/api/verifyOtp", requestOptions)
      .then((response) => response.json())
      .then((result) => {

        // console.log(result);
        if (JSON.stringify(result) === '["error","Your OTP has been expired"]') {
          toast.error("Your One Time Passcode has been expired", { autoClose: 1000 });
        } else if (result.errors) {

        } else {
          Setcode("")
          toast.success("User Login successfully", { autoClose: 1000 });
          localStorage.setItem("vacayid", result.data.id);
          localStorage.setItem(
            "namevacay",
            result.data.fname + " " + result.data.lname
          );
          navigate("/");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleLogin = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Mobile Number is required";
    } else if (phoneNumber.trim().length < 10) {
      newErrors.phoneNumber = "Mobile Number should be at least 10 digits";
    }
    if (!phoneNumber.startsWith("+1")) {
      setnewPhoneNumber( "+1" + phoneNumber);
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    // Setloader(true)
    setTimeout(function () {
      var button = document.getElementById('getotp2');
      console.log(button,'button')
      if(button !==undefined &&button !=null){
        button.style.display = 'block';
        button.style.background = 'transparent'
      }
    
    }, 20000);
 
    var formdata = new FormData();

    formdata.append("mobile_no","+1" + phoneNumber);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(apiurl + "/api/loginOtp", requestOptions)
      .then((response) => response.json())
      .then((result) => {
 
        if (
          result.message === "user not found" 
    
        ) {
          toast.error("The selected mobile no is invalid.", {
            autoClose: 3000,
          });
        }else if(  result.message === "Please provide a valid mobile number from the USA."){
          toast.error("Please provide a valid mobile number from the USA.", {
            autoClose: 3000,
          });
        }else if(  result.message === "Failed to send OTP"){
          toast.error("Failed to send OTP", {
            autoClose: 3000,
          })}
           else {
          // const otp = result[1].match(/\d+/)[0];

          Setloader(false);
          Setcode("otp");
          SetUserid(result.user_id);

      
        }
      })
      .catch((error) => console.log("error", error));
  };
  const Modalcloselogin = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="signup-container">
          {showModal && (
            <div className="custom-modal login">
              <div className="modal-header">
                <h1 className="modal-title">Login</h1>
                <button
                  type="button"
                  className="close"
                  onClick={Modalcloselogin}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {code !== "" ? (
                  <>
                    <form className="fromvacaysl">
                      <div className="form-group">
                        <label>Verify Your One Time Passcode</label>
                        <div className="otp-input-container">
                          {otp.map((value, index) => (
                            <input
                              key={index}
                              // className="otp-button"
                              maxLength={1}
                              value={value}
                              onChange={(e) => handleotpChange(index, e)}
                              ref={refs[index]}
                            />
                          ))}
                        </div>
                        {/* Display error message if any */}
                        {errors.otp && (
                          <span className="error-message">{errors.otp}</span>
                        )}
                      </div>
                 
                      <button onClick={handleotplogin}>Login</button>

                    </form>
                  </>
                ) : (
                  <>
                    <form className="fromvacaysl">
                      <div className="form-group">
                        <label>
                          {" "}
                          Mobile Number{" "}
                          <span className="error">
                            *
                          </span>
                        </label>
                        <div className="input-container">
                        <span className="prefix">+1</span>
                        <input
                          type="text"
                          placeholder="Mobile Number"
                          value={phoneNumber}
                          onChange={hnadleInputChangelogin}
                          onClick={() => handleInputClick("phoneNumber")}
                          name="phoneNumber"
                          className={errors.phoneNumber ? "error2" : ""}
                        />
                   
                        </div>
                     
                      </div>
                      <div className="error-mobile">
                      {errors.phoneNumber && (
                          <span className="error-message">
                            {errors.phoneNumber}
                          </span>
                        )}
                        </div>
                      <h5>
                        <button id="getotp2"  onClick={handleLogin}><span> Resend One Time Passcode</span></button>
                      </h5>
                      <button id="getotp" onClick={handleLogin}>Get One Time Passcode</button>
                      <h5>
                        Not a customer? <Link to="/signup">Sign Up</Link>
                      </h5>

                    </form>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
