import React, { useEffect, useState } from "react";
import { apiurl } from "../../url";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Featuredproperty = () => {
  const navigate = useNavigate();
  const [propertydata, Setpropertydata] = useState([]);

  useEffect(() => {
    fetch(apiurl + "/api/property/feature")
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        Setpropertydata(result.data);
      })
      .catch((error) => { });
  }, []);

  const handleViewAllClick = () => {
    navigate("/properties");
  };
  return (
    <>

      {propertydata.length != "0" && propertydata[0].status === 'yes' ? (
        <div className="featured-container">
          <div className="featuredmain-container">
            <h1 className="property_heading">Featured Properties</h1>
          </div>
          <div className="featuredimage-container">
            <div className="featuredproperty_imagebox">
              <div className="rowfeaturedproperty">
                {propertydata.length > 0 &&
                  propertydata
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.updated_at) - new Date(a.updated_at)
                  ).map((item, index) => {
                    let buttonText;
                    if (index % 3 === 0) {
                      buttonText = "View More";
                    } else if (index % 3 === 1) {
                      buttonText = "Check It Out";
                    } else {
                      buttonText = "Learn More";
                    }
                    if (index < 4) {
                      return <div key={index} className="outerboxfeatured_image">
                        <div className="propertyfeatured_image">
                          <img
                            src={apiurl + "/img/property/main-img/" + item.main_img}
                            alt="propertyimage"
                          />
                      
                          <button>
                            <Link to={`/property-details/${item.id}`}>{buttonText}</Link>
                          </button>
                        </div>
                        <p>{item.title}</p>
                      </div>
                    }
                  })}
              </div>
              <div className="featuredbutton">
                <button onClick={handleViewAllClick}>View all Properties</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
        </>
      )}



      {/**/}
    </>
  );
};

export default Featuredproperty;
