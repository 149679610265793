import React, { useEffect, useState } from "react";
import Header from "../Common/Header";

import Footer from "../Common/Footer";
import { apiurl } from "../url";

const Faq = () => {
  const [loader, Setloader] = useState(false);
  const [stop, setStop] = useState(true);
  const [data, setData] = useState("");

  useEffect(() => {
    if (stop === true) {
      setStop(false);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const request = new Request(`${apiurl}/api/getfaq`, requestOptions);

      fetch(request)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
      
          setData(result.data);
  
          Setloader(false);
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
        });
    }
  }, [stop]);

  const [isVisible, setIsVisible] = useState([true, ...Array(data?data.length - 1:"").fill(false)]);




  const toggleAnswerVisibility = (index) => {
    const updatedVisibility = [...isVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setIsVisible(updatedVisibility);
  };

  return (
    <>
          <Header />
    <div className="faq-banner_section">
    <div className="faqbanner_content">
     
            <h1 className="t-h2">Frequently Asked Questions</h1>
           
    </div>
</div>
      <div className="faq-contianer">
  
        <div className="faqmain-container">
          <h2 className=""></h2></div>
          <div className="abouhome_container faq">
            {data.length >= 1
              ?   data.map((dataItem, index2) => (
                <div className="faqans" key={index2}>
                  <h4
                    id={"tag" + index2}
                    className="question-container"
                  >
                    <span className="question-text" onClick={() => toggleAnswerVisibility(index2)}>
                      {dataItem.question}
                    </span>
        
                    <i
                      className={`fa fa-chevron-right icon ${isVisible[index2] ? "open" : ""}`}
                      onClick={() => toggleAnswerVisibility(index2)}
                      style={{
                        transform: isVisible[index2] ? "rotate(90deg)" : "rotate(360deg)",
                        cursor: "pointer",
                        color:"#7cf7e5",
                      
                      }}
                    />
                  </h4>
        
                  <div className="faqanswer"
                    dangerouslySetInnerHTML={{
                      __html: isVisible[index2] ? dataItem.answer : "",
                    }}
                  />
                </div>
              ))
              : null}
              
          </div>
        
 
      </div>
      <Footer />
    </>
  );
};

export default Faq;
