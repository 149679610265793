import React, { useEffect, useState } from "react";
import Header from "../Common/Header";

import Footer from "../Common/Footer";
import { apiurl } from "../url";

const Termandconditions = () => {
  const [loader, Setloader] = useState(false);
  const [stop, setStop] = useState(true);
  const [data, setData] = useState("");

  useEffect(() => {
    if (stop === true) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const request = new Request(`${apiurl}/api/terms`, requestOptions);

      fetch(request)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
          // console.log(result.terms.details, "test data");
          setData(result);
          setStop(false);
          Setloader(false);
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
        });
    }
  }, [stop]);

  return (
    <>
      <Header />

      <div class="faq-banner_section">
        <div class="faqbanner_content">
          <h1 class="t-h2">Terms and Conditions</h1>
        </div>
      </div>
      <div className="faq-contianer">
        <div className="abouhome_container faq">
          <p
            dangerouslySetInnerHTML={{
              __html: data.terms ? data.terms.details : "",
            }}
          ></p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Termandconditions;
