import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { apiurl } from "../url";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";



const PropertyDetails = () => {
  const { id, encryptedCode } = useParams();
  const [loader, Setloader] = useState(true);

  const [userid, setuserid] = useState(
    localStorage.getItem("vacayid") ? localStorage.getItem("vacayid") : ""
  );
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showHistory, setShowhistory] = useState(true);
  const [token, setToken] = useState(true);
  const [propertydetail, Setpropertydeatil] = useState([]);
  const [propertypermission, Setpropertypermission] = useState("");
  const [facility, Setfacility] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [date, setdate] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [res,Setres]=useState([])
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });

  const [propertydetailimage, Setpropertydeatilimage] = useState([]);

  useEffect(() => {
    const propertyId = id;
    fetch(`${apiurl}/api/property-details?propertyId=${propertyId}`)
      .then((response) => response.json())
      .then((result) => {
        Setloader(false);
        Setres(result.data)
        const propertyImages = result.data.property_images;
        const mainImage = {
          images: result.data.property.main_img,
          // Add any other properties of main image if needed
        };
        const propertyImagesWithMain = [mainImage, ...propertyImages];
        Setpropertydeatilimage(propertyImagesWithMain);
        Setpropertydeatil(result.data.property);
        Setpropertypermission(result.data.users_with_permission);
        setToken(result.data.users_with_permission[0].token);
       
        Setfacility(result.data.facilities);
      })
      .catch((error) => {});
  }, [id]);
  const hnadleInputChangesubmit = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });

    if (name === "firstName" || name === "lastName") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Alphabets are allowed" });
        return;
        // setFirstName(value);
      }
    }
    if (name === "phoneNumber") {
      if (!/^\d*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Numbers are allowed" });
        return;
      }

      if (value.length > 13) {
        setErrors({
          ...errors,
          [name]: "Maximum 13 digit Phone no is allowed",
        });
        return;
      }
    }

    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "message") {
      setMessage(value);
    } else if (name === "phoneNumber") {
      setPhoneNumber(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };
  const handleEnquiry = (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!firstName.trim()) {
      newErrors.firstName = "Name is required";
    }
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Mobile Number is required";
    } else if (phoneNumber.trim().length < 10) {
      newErrors.phoneNumber = "Mobile Number should be at least 10 digits";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else {
      const emailRegex = /^[a-zA-Z0-9.]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$/;

      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter valid email address.";
      }
    }
    if (!message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      return;
    }

    if (
      firstName !== "" &&
      message !== "" &&
      phoneNumber !== "" &&
      phoneNumber.length >= 10
    ) {
      document.querySelector("#enquiry-button").disabled = true;
      var formdata = new FormData();
      formdata.append("name", firstName);
      formdata.append("message", message);
      formdata.append("email", email);
      // formdata.append("phone", phoneNumber);
      formdata.append("phone", "+1" + phoneNumber);
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(apiurl + "/api/enquiry", requestOptions)
        .then((response) => {
          if (!response.ok) {
          }
          return response.json();
        })
        .then((result) => {
          // console.log(result);

          if (result.message === "Enquiry add successfully") {
            toast.success(" Enquiry add successfully", { autoClose: 1000 });
            setFirstName("");
            setPhoneNumber("");
            setEmail("");
            setMessage("");
            setShowModal(false);
          } else if (result.errors) {
            toast.error(result.errors, { autoClose: 1000 });
          }
        })
        .catch((error) => {});
    } else {
    }
  };

  const Modalclosesignup = () => {
    setShowModal(false);
    setFirstName("");
    setPhoneNumber("");
    setEmail("");
    setMessage("");
  };
  const Modalopen = () => {
    setShowModal(true);
    window.scrollTo(0, 20);
  };

  const handleInputClick = (name) => {
    // console.log(name, "name ");
    setErrors({ ...errors, [name]: "" });
  };


  const [showmodal, setShowmodal] = useState(false);
 

  useEffect(() => {
    if (encryptedCode !== undefined && showHistory === true) {
      setShowhistory(false);
      const payload = {
        token: encryptedCode,
        // user_id: logincheck.id,
        link: id,
      };

      fetch(`${apiurl}/api/storePaymentDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((result) => {
          // if (result.message !== "Status Failed") {
          toast.success("Payment Successfull", { autoClose: 1000 });
          setTimeout(() => {
            window.location.replace(window.location.origin + "/");
          }, 1200);
          // }
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
        });
    }
  }, [token]);
  const smallerImagesRef = useRef(null);

  const handleBiggerImageClick = () => {
    // Triggering click on smaller images
    smallerImagesRef.current.click();
  };
  return (
    <>
      {loader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="propertydeatilsmain-container">
          <Header />
          <div className="propertydetails-conatiner">
            {/* */}
            <div className="propertydetailsheading">
              <h2>{propertydetail?.title}</h2>
            </div>
            <div className="productsdeatils_imagebox">
              <div className="main_image">
                <div className="Imagespropertydetails-box">
                  <div
                    className="biigerimages"
                    onClick={handleBiggerImageClick}
                  >
                    {propertydetail?.main_img !== undefined && (
                      <img
                        src={
                          apiurl +
                          "/img/property/main-img/" +
                          propertydetail?.main_img
                        }
                        alt="propertyimage"
                      />
                    )}
                  </div>

                  <div
                    className="modal-overlay"
                    style={{
                      display: showmodal || showModal2 ? "block" : "none",
                    }}
                  ></div>
                  <div className="smallerimages">
                    {window.screen.width > 600 ? (
                      <Gallery>
                        {propertydetailimage.map((image, index) => (
                          <div
                            key={index}
                            className="boxessmallerimage"
                            style={
                              index > 4 || index === 0
                                ? { display: "none" }
                                : {}
                            }
                          >
                          
                            <Item
                              original={
                                index === 0
                                  ? `${apiurl}/img/property/main-img/${image.images}`
                                  : `${apiurl}/img/property/property-imgs/${image.images}`
                              }
                              thumbnail={
                                index === 0
                                  ? `${apiurl}/img/property/main-img/${image.images}`
                                  : `${apiurl}/img/property/property-imgs/${image.images}`
                              }
                              width="1024"
                              height="768"
                            >
                              {({ ref, open }) => (
                                <img
                                  ref={(el) => {
                                    ref(el);
                                    if (index === 0) {
                                      smallerImagesRef.current = el;
                                    }
                                  }}
                                  onClick={() => {
                                    open();
                                  }}
                                  src={
                                    index < 5 && index !== 0
                                      ? `${apiurl}/img/property/property-imgs/${image.images}`
                                      : index === 0
                                      ? `${apiurl}/img/property/main-img/${image.images}`
                                      : ""
                                  }
                                  alt={`propertyimage ${index}`}
                                  style={index > 4 ? { display: "none" } : {}}
                                />
                              )}
                            </Item>

                            {index === 4 &&
                              index <= propertydetailimage.length && (
                                <span>
                                  +{propertydetailimage.length - 5} More
                                </span>
                              )}
                          </div>
                        ))}
                      </Gallery>
                    ) : (
                      <Gallery>
                        {propertydetailimage.map((image, index) => (
                          <div
                            key={index}
                            className="boxessmallerimage"
                            style={
                              index > 1 || index === 0
                                ? { display: "none" }
                                : {}
                            }
                          >
                            <Item
                              original={
                                index === 0
                                  ? `${apiurl}/img/property/main-img/${image.images}`
                                  : `${apiurl}/img/property/property-imgs/${image.images}`
                              }
                              thumbnail={
                                index === 0
                                  ? `${apiurl}/img/property/main-img/${image.images}`
                                  : `${apiurl}/img/property/property-imgs/${image.images}`
                              }
                              width="1024"
                              height="768"
                            >
                              {({ ref, open }) => (
                                <img
                                  ref={(el) => {
                                    ref(el);
                                    if (index === 0) {
                                      smallerImagesRef.current = el;
                                    }
                                  }}
                                  onClick={() => {
                                    open();
                                  }}
                                  src={
                                    index < 4 && index !== 0
                                      ? `${apiurl}/img/property/property-imgs/${image.images}`
                                      : index === 0
                                      ? `${apiurl}/img/property/main-img/${image.images}`
                                      : ""
                                  }
                                  alt={`propertyimage ${index}`}
                                  style={
                                    index === 1
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                />
                              )}
                            </Item>
                          
                            {index === 1 &&
                              index <= propertydetailimage.length - 4 && (
                                <span>
                                  +{propertydetailimage.length - 2} More
                                </span>
                              )}
                          </div>
                        ))}
                      </Gallery>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="modal-overlay"
                style={{ display: showModal ? "block" : "none" }}
              ></div>
              <div className="enquirydiv">
                {/* {propertypermission != null &&
                propertypermission[0] != undefined &&
                logincheck.id == propertypermission[0].user_id &&
                encryptedCode == undefined &&
                token != null ? (
                  <button onClick={handleClick}>Pay For Booking</button>
                ) : (
                  <></>
                )} */}

                <button onClick={Modalopen}>Request a Booking</button>
              </div>
              <div className="aboutdetails-box">
                <h2>$ {propertydetail?.price}</h2>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 384 512"
                  >
                    <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                  </svg>
                  {propertydetail?.location}
                </p>
              </div>

              <div className="card-wrapper">
                <div className=" ">
                  <h2>Description</h2>
                  <br />
                  <p className="decrsiptionp">{propertydetail?.description}</p>
                </div>
              </div>

              <div className="card-wrapper">
                <div className="highlight  highlight2">
                  <h3>Highlights</h3>
                  <br />
                  <ul className="highlight multyside">
                    <li className="flex">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="21"
                          viewBox="0 0 384 512"
                        >
                          <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                        </svg>
                      </div>
                      <div className="content">
                        <h4>Location</h4>
                        <p className="notranslate"> {propertydetail?.location}</p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="21"
                          viewBox="0 0 640 512"
                        >
                          <path d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z"></path>
                        </svg>
                      </div>
                      <div className="content">
                        <h4>Bedrooms</h4>
                        <p>{propertydetail?.no_of_beds}</p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 512 512"
                        >
                          <path d="M32,384a95.4,95.4,0,0,0,32,71.09V496a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V480H384v16a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V455.09A95.4,95.4,0,0,0,480,384V336H32ZM496,256H80V69.25a21.26,21.26,0,0,1,36.28-15l19.27,19.26c-13.13,29.88-7.61,59.11,8.62,79.73l-.17.17A16,16,0,0,0,144,176l11.31,11.31a16,16,0,0,0,22.63,0L283.31,81.94a16,16,0,0,0,0-22.63L272,48a16,16,0,0,0-22.62,0l-.17.17c-20.62-16.23-49.83-21.75-79.73-8.62L150.22,20.28A69.25,69.25,0,0,0,32,69.25V256H16A16,16,0,0,0,0,272v16a16,16,0,0,0,16,16H496a16,16,0,0,0,16-16V272A16,16,0,0,0,496,256Z"></path>
                        </svg>
                      </div>
                      <div className="content">
                        <h4>Bathrooms</h4>
                        <p>{propertydetail?.no_of_bathrooms}</p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="21"
                          viewBox="0 0 576 512"
                        >
                          <path d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path>
                        </svg>
                      </div>
                      <div className="content">
                        <h4>Type</h4>
                        <p>{propertydetail?.property_type}</p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="icon">$</div>
                      <div className="content">
                        <h4>Average Price Per Night</h4>
                        <p>$ {propertydetail?.price}</p>
                      </div>
                    </li>
                    {/* {facility?.map((facility) => (
                      <li key={facility.id} className="flex">
                        <span
                          className="facility-icon"
                          dangerouslySetInnerHTML={{
                            __html: facility.icons,
                          }}
                        ></span>
                        <span className="facility-title">
                          {facility.title}
                        </span>
                      </li>
                    ))} */}
                  </ul>
                </div>
                
                {res.facilities?.length > 0 ? (
                  <div className="highlight highlight2">
                    <h3>Facilities</h3>
                    <br />
                    <ul className="highlight multyside">
                      {res.facilities?.map((data, index) => {
                        return (
                          <li className="flex">
                            <img
                              className="icon"
                              src={
                                data?.icons
                                  ? `${apiurl}/storage/icons/${data?.icons}`
                                  : ""
                              }
                            ></img>
                            <div className="content">
                              <h4>{data?.title}</h4>
                              <p className="notranslate"> {data?.subtitle}</p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {showModal && (
            <div className="modal-containerenquiry enquiry">
              <div className="modal-header">
                <h1 className="modal-title">Enquiry</h1>
                <button
                  type="button"
                  className="modal-close"
                  onClick={Modalclosesignup}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="fromvacaysl">
                  <div className="form-group">
                    <label>
                      Name <span className="error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={firstName}
                      name="firstName"
                      onChange={hnadleInputChangesubmit}
                      onClick={() => handleInputClick("firstName")}
                      className={errors.firstName ? "error2" : ""}
                    />
                    {errors.firstName && (
                      <span className="error-message">{errors.firstName}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <span className="error">*</span>
                    <input
                      type="text"
                      placeholder="Email address"
                      value={email}
                      onChange={hnadleInputChangesubmit}
                      onClick={() => handleInputClick("email")}
                      name="email"
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Mobile Number <span className="error">*</span>
                    </label>
                    <div className="input-container">
                      <span className="prefix">+1</span>
                      <input
                        type="text"
                        placeholder="Mobile Number"
                        value={phoneNumber}
                        onChange={hnadleInputChangesubmit}
                        onClick={() => handleInputClick("phoneNumber")}
                        name="phoneNumber"
                        className={errors.phoneNumber ? "error2" : ""}
                      />
                    </div>

                    {errors.phoneNumber && (
                      <div class="error-mobile"><span className="error-message">{errors.phoneNumber}</span></div>
                    
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Message <span className="error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Message"
                      value={message}
                      name="message"
                      onChange={hnadleInputChangesubmit}
                      onClick={() => handleInputClick("message")}
                      className={errors.firstName ? "error2" : ""}
                    />
                    {errors.message && (
                      <span className="error-message">{errors.message}</span>
                    )}
                  </div>

                  <button
                    className="submit-button"
                    id="enquiry-button"
                    onClick={handleEnquiry}
                    disabled={false}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}

          <Footer />
        </div>
      )}
    </>
  );
};

export default PropertyDetails;
