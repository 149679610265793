import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/vacaydroplogo.png";

import { useNavigate } from "react-router-dom";
import messagesquare from "../images/message-square.png";
import { apiurl } from "../url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const navigate = useNavigate();
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  const [loader, Setloader] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);
  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleLogoutClick = () => {
    Setloader(true);

    localStorage.removeItem("vacayid");
    localStorage.removeItem("namevacay");
    setTimeout(() => {
      window.location.replace("/");
    }, 500);
  };

  const [propertydetail, Setpropertydeatil] = useState([]);

  useEffect(() => {
    const apiUrl = `${apiurl}/api/joinnow`;
    if (logincheck.id != null) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      fetch(`${apiUrl}?user_id=${logincheck.id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          Setpropertydeatil(result.property);
        })
        .catch((error) => {
          toast.error(error.errors, { autoClose: 1000 });
        });
    }
  }, []);
  const handleaboutclick = () => {
    let aboutContainer = document?.querySelector("#about-continer");

    if (aboutContainer) {
   
      aboutContainer.scrollIntoView({ behavior: "smooth" });
    } else {
    
    }
  };
  const handleaboutproperty = () => {

 
  navigate('/properties');
  window.location.reload()


  };

  useEffect(()=>{
    if (window.location.hash==="#about-us"){
      handleaboutclick()
    }else{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },[window.location])
  return (
    <>
      {loader && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="main-container">
        <header>
          <nav>
            <ul className="nav-bar">
              <li className="logo">
                <Link to="/">
                  <div className="logobox">
                    <img src={logo} alt="vacaydroplogo" title="vacaydroplogo" />
                  </div>
                </Link>
              </li>
              <input type="checkbox" id="check" />
              <span className="menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                
                {window.location.pathname==="/" ?<Link onClick={handleaboutclick}>About us</Link>:<Link to="/#about-us">About us</Link>}
                </li>
                <li>
                  {/* <Link to="/properties">Properties</Link> */}
                    
                {window.location.pathname==="/properties" ?<Link onClick={handleaboutproperty}>Properties</Link>:<Link to="/properties">Properties</Link>}
                </li>
                <li>
                  <Link to="/Howitworks">How it works</Link>
                </li>
                <li>
                  <Link to="/Faq">FAQ</Link>
                </li>
        
             
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                {logincheck.id !== null && logincheck.name !== null ? (
                  <>
                  {/* <li className="activelogin">
                  <Link to="/saved-property">
                    <button className="messagesquarebutton">
                      <span>{propertydetail?.length}</span>
                      <img
                        src={messagesquare}
                        // alt="vacaydropmessagesquare"
                        title=""
                      />
                    </button>
                  </Link>
                </li> */}
                    <li>
                      <div className="dropdown">
                        <div
                          className="dropdown-header"
                          onClick={toggleDropdown}
                        >
                          <a className="username">
                            {" "}
                            <span>
                              {logincheck.name ? logincheck.name : "username"}{" "}
                            </span>
                          </a>

                          <i
                            className={`fa fa-chevron-right icon ${
                              isOpen ? "open" : ""
                            }`}
                            style={{
                              transform: isOpen
                                ? "rotate(90deg)"
                                : "rotate(90deg)",
                            }}
                          ></i>
                        </div>
                        <div className={`dropdown-body ${isOpen && "open"}`}>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setOpen(!isOpen);
                            }}
                          >
                            <ul>
                              <li>
                                <Link to="/myprofile">
                                  <span>
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                  </span>{" "}
                                  My Profile
                                </Link>
                              </li>
                              <li>
                                <Link to="/myBooking">
                                  <span className="booking-icon">
                                    {" "}
                                    <i className="fas fa-calendar fa-sm fa-fw mr-2 text-gray-400"></i>
                                  </span>
                                  My Booking
                                </Link>
                              </li>
                              <li>
                                <Link onClick={handleLogoutClick}>
                                  {" "}
                                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>{" "}
                                  Logout
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button
                        className="signupbutton"
                        onClick={handleSignupClick}
                      >
                        Join Now
                      </button>
                    </li>
                    <li>
                      <button
                        className="signupbutton transparent"
                        onClick={handleLoginClick}
                      >
                        Login
                      </button>
                    </li>
                  </>
                )}

                {/* <li><a class="" href="/">shubham saxena </a><ul><li><a href="/search-parents/Account">Moj profil</a></li><li>Odjavi se</li></ul></li>   */}

                <label htmlFor="check" className="close-menu">
                  <i className="fas fa-times"></i>
                </label>
              </span>
              <label htmlFor="check" className="open-menu">
                <i className="fas fa-bars"></i>
              </label>
            </ul>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Header;
