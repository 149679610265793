
import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import Signup from './Pages/Signup';
import Login from './Pages/Login';
import Properties from './Components/Properties';
import PropertyDetails from './Pages/Propertydetails';
import Profile from './Pages/Profile';
import Privacypolicy from './Pages/Privacypolicy';
import Termandconditions from './Pages/Termsandcondition';
import Bookinghistory from './Pages/Bookinghistory';
import Faq from './Pages/Faq';
import Howitworks from './Pages/Howitworks';
import { ToastContainer } from 'react-toastify';


import Contact from './Pages/Contact';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Savedproperty from './Pages/Savedproperty';



function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about-us' element={<About />} />
          <Route exact path='/properties' element={<Properties />} />
          <Route exact path='/signup' element={<Signup />} />
          <Route exact path='/login' element={<Login />} />
          <Route path="/property-details/:id" element={<PropertyDetails />} />
          <Route path="/property-details/:id/:encryptedCode" element={<PropertyDetails />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/myprofile' element={<Profile />} />
          <Route exact path='/privacy-policy' element={<Privacypolicy />} />
          <Route exact path='/term-and-conditions' element={<Termandconditions />} />
          <Route exact path='/saved-property' element={<Savedproperty />} />
          <Route exact path='/myBooking' element={<Bookinghistory />} />
       
          <Route exact path='/Faq' element={<Faq />} />
          <Route exact path='/Howitworks' element={<Howitworks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover />
    </>
  );
}
const NotFound = () => {
  return <h1>404 - Not Found</h1>;
};

export default App;
