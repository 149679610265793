import React, { useEffect, useState } from "react";
import Header from "../Common/Header";

import { apiurl } from "../url";
import Footer from "../Common/Footer";

const Privacypolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
// https://vacaydropadmin.sdsstaging.co.uk/api/privacy
    const request = new Request(
      `${apiurl}/api/privacy`,
      requestOptions
    );

    fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        setPrivacyPolicy(result);
        // console.log(result, "fghj");
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
       <Header />
       <div className="faq-banner_section">
    <div className="faqbanner_content">
     
            <h1 className="t-h2">Privacy policy</h1>
           
    </div>
</div>
<div className="faq-contianer">
        <div className="abouhome_container faq">
   
          <div className="privacymaincontact">
            <ol>
              <li>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      privacyPolicy &&
                      privacyPolicy.privacyPolicy &&
                      privacyPolicy.privacyPolicy.details
                        ? privacyPolicy.privacyPolicy.details
                        : "",
                  }}
                />
              </li>
            </ol>
          </div>
        </div>
  
      </div>
      <Footer />
    </>
  );
};

export default Privacypolicy;
