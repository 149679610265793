import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Abouthomecommon from "../Pages/Homecommon/Abouthome";
import Nearbyproperties from "../Pages/Homecommon/Nearbypropeties";
import Featuredproperty from "../Pages/Homecommon/Featuredproperty";
import Sitework from "../Pages/Homecommon/Sitework";
import Footer from "../Common/Footer";
import { apiurl } from "../url";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Faq from "../Pages/Faq";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import PlacesAutocomplete from "react-places-autocomplete";

const Home = () => {
  const navigate = useNavigate();
  const [stop, setStop] = useState(true);
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [search, setearch] = useState("");
  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("vacayid"),

    name: localStorage.getItem("namevacay"),
  });
  

  useEffect(() => {
    if (stop === true) {
      const endpoint = "/api/banner";
      fetch(`${apiurl}${endpoint}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((result) => {
          setData(result);
          setStop(false);
        })
        .catch((error) => {});
    }
  }, [stop]);

  useEffect(() => {
    if (data) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.data.length);
      }, 12000);
      return () => clearInterval(interval);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.data.length > 0 && isHovered === false && address === "") {
      const backgrounds = document.getElementsByClassName("colorlayer");
      const endpoint = "/img/banner/";
      // const apiurls = `${apiurl}${endpoint}`;
      const nextIndex = (currentIndex + 1) % data.data.length;
      const nextImage = new Image();
      nextImage.src = `${apiurl}${endpoint}${data.data[nextIndex].banner}`;

      const contentContainers =
        document.getElementsByClassName("content_wrapper");
      const contentContainers2 =
        document.getElementsByClassName("home-searchbox2");
      const contentContainers3 =
        document.getElementsByClassName("home_mainheading");
      const contentContainers4 =
        document.getElementsByClassName("home-searchbox");
      const contentContainers5 =
        document.getElementsByClassName("home-searchbox3");

      const numContainers = contentContainers.length;

      const showContentAtIndex = (indexToShow) => {
        for (let i = 0; i < numContainers; i++) {
          // console.log(i,indexToShow,'i and index')
          if (indexToShow === 0) {
            if (contentContainers2 !== undefined) {
              contentContainers2[i].style.display = "none";
            }

            contentContainers3[i].style.display = "none";
            contentContainers4[i].style.display = "none";
            contentContainers5[i].style.display = "block";
          } else if (indexToShow === 2) {
            if (contentContainers2 !== undefined) {
              contentContainers2[i].style.display = "none";
            }
            //  css-13cymwt-contro
            contentContainers3[i].style.display = "block";
            contentContainers4[i].style.display = "block";

            contentContainers5[i].style.display = "none";
          } else if (indexToShow === 1) {
            if (contentContainers2 !== undefined) {
              contentContainers2[i].style.display = "block";
            }

            contentContainers3[i].style.display = "block";
            contentContainers4[i].style.display = "none";
            contentContainers5[i].style.display = "none";
          }
        }
      };

      showContentAtIndex();

      nextImage.onload = () => {
        for (let i = 0; i < backgrounds.length; i++) {
          backgrounds[i].style.backgroundImage = `url(${nextImage.src})`;
        }
        // console.log(currentIndex)
        const nextContentIndex = (currentIndex + 1) % 3;
        // console.log(nextContentIndex,'next')
        if (nextContentIndex === 0) {
          showContentAtIndex(1);
        } else if (nextContentIndex === 1) {
          showContentAtIndex(0);
        } else {
          showContentAtIndex(2);
        }
      };
    }
  }, [data, currentIndex, search]);
  const handlePrevious = () => {
if(data!=null){
  setCurrentIndex(
    (prevIndex) => (prevIndex - 1 + data.data.length) % data.data.length
  );
}
 
  };

  const handleNext = () => {
    if(data!=null){
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.data.length);
    }

  };

  const searchfun = () => {
    if (address !== "") {
      navigate("/properties?search=" + address);
    } else {
      toast.error("Please Enter Something.", {
        autoClose: 3000,
      });
    }
  };
  const joinNow = () => {
    navigate("/signup");
  };

  function joinNowafter_login() {
    const aboutUsSection = document.getElementById("lastminuteproperty");
    aboutUsSection.scrollIntoView({ behavior: "smooth" });
  }

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [address, setAddress] = useState("");

  const handleSelect = (selectedAddress) => {
    setAddress(selectedAddress);
  };



  return (
    <>
      <Header />
      <div className="carousel-container"></div>

      <div className="colorlayer">
        <div className="main-container">
          <div className="home-container">
            <div class="content_wrapper">
              <div className="home_mainheading">
                <h1>Last Minute Vacation Property Rentals</h1>
              </div>
              <div className="home-searchbox2">
                {logincheck.id == null || logincheck.name == null ? (
                  <>
                    <button onClick={joinNow}>Join now</button>
                  </>
                ) : (
                  <>
                    <button onClick={joinNowafter_login}>Join now</button>
                  </>
                )}
              </div>

              <div
                className="home-searchbox"
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                // style={{ cursor: isHovered ? "none" : "auto" }}
              >
                <h1 className="h1-dash">Enter Location/ZipCode</h1>
                <div className="home_input-box">
                  {/* <GooglePlacesAutocomplete
                      apikey="AIzaSyBgIV0oB5Ytj3Un31M693lKmoGOPFC-Z5o"
                      selectProps={{
                        search,
                        onChange: setearch,
                        placeholder: 'Search here'
                      }}
                      autocompletionRequest={{
                        types: ['(regions)'],
                        strictbounds: true,
                        componentRestrictions: {
                          country: 'us' 
                        }
                      }}
                      
                    /> */}
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                    searchOptions={{
                      types: ["address"],
                      componentRestrictions: { country: "US" },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <>
                        <input
                          {...getInputProps({
                            placeholder: "Search here",
                            style: {
                              padding: "10px",

                              border: "1px solid #999999",
                              borderRadius: "5px",
                            },
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {/* {loading && <div>...</div>} */}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>

                  <button type="submit" onClick={searchfun}>Search</button>
                </div>
              </div>

              <Sitework />
            </div>
          </div>
     
            <button onClick={handlePrevious} className="buttonsliderprevious">
              {" "}
              <i
                className={"fa fa-chevron-right icon "}
                style={{ color: "#fff", transform: "rotate(180deg)" }}
              ></i>
            </button>
            <button onClick={handleNext} className="buttonslidernext">
              {" "}
              <i
                className={"fa fa-chevron-right icon "}
                style={{ color: "#fff" }}
              ></i>
            </button>
        
        </div>
      </div>

      <Abouthomecommon />

      <Nearbyproperties />
      <Featuredproperty />
      <Footer />
    </>
  );
};

export default Home;
