import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { apiurl } from "../url";

const Contact = () => {
  const [showModal, setShowModal] = useState(true);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const hnadleInputChangesubmit = (e) => {

    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });

    if (name === "name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Alphabets are allowed" });
        return;
      }
    }

    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "message") {
      setMessage(value);
    }
  };
  const handleInputClick = (name) => {
    setErrors({ ...errors, [name]: "" });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
 
    const newErrors = {};
    if (!name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required";
    } else {
      const emailRegex = /^[a-zA-Z0-9.]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$/;

      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter valid email address.";
      }
    }
    if (!message.trim()) {
      newErrors.message = "Message is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    document.querySelector("#contact-ussubmit").disabled = true
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("message", message);
    var requestOptions = {
      method: "POST",
      body: formdata,
    };
    fetch(apiurl + "/api/contact", requestOptions)
      .then((response) => {
        if (!response.ok) {
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result.success === "Thank you for contacting us!") {
          setName("");
          setEmail("");
          setMessage("");
          toast.success("Thank you for contacting us!");
          navigate("/");
        } else {
          toast.error("failed");
        }
      })
      .catch((error) => {});
  };
  const Modalclosesignup = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <>
    <Header />
      <div className="contact-container">
        <div className="mainconatct-container">
          {showModal && (
            <div className="custom-modal1">
              <div className="modal-header">
                <h1 className="modal-title">Contact Us</h1>
                <button
                  type="button"
                  className="close"
                  onClick={Modalclosesignup}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="fromvacaysl">
                  <div className="form-group">
                    <label>
                      Name <span className="error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Name"
                      value={name}
                      name="name"
                      onChange={hnadleInputChangesubmit}
                      onClick={() => handleInputClick("name")}
                      className={errors.name ? "error2" : ""}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Email <span className="error">*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Email address"
                      value={email}
                      name="email"
                      onChange={hnadleInputChangesubmit}
                      onClick={() => handleInputClick("email")}
                      className={errors.email ? "error2" : ""}
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                  <div className="form-group1">
                    <label>
                      Message <span className="error">*</span>
                    </label>
                    <textarea
                      type="text"
                      placeholder="Message"
                      value={message}
                      onChange={hnadleInputChangesubmit}
                      name="message"
                      onClick={() => handleInputClick("message")}
                      className={errors.message ? "error2" : ""}
                    ></textarea>
                    {errors.message && (
                      <span className="error-message">{errors.message}</span>
                    )}
                  </div>
                  <div>
                    {" "}
                    <button onClick={handleSubmit} id="contact-ussubmit" disabled={false}>Contact Us</button>
                    {/* <ToastContainer autoClose={1000} /> */}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
