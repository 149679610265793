import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiurl } from "../url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const [showModal, setShowModal] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const refs = [useRef(), useRef(), useRef(), useRef()];
  const [otpsignup, setotpsignup] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [agrement, setagrement] = useState("");
  const [code, Setcode] = useState(false);
  const [errors, setErrors] = useState({});
  const [loader, Setloader] = useState(false);
  const navigate = useNavigate();
  const [userid, SetUserid] = useState("");
  const handleotpsignupChange = (index, e) => {
    const { value } = e.target;
    setotpsignup((prevotpsignup) => {
      const newotpsignup = [...prevotpsignup];
      newotpsignup[index] = value;

      if (value && index < 3) {
        refs[index + 1].current.focus();
      }

      if (!value && index > 0) {
        refs[index - 1].current.focus();
      }

      return newotpsignup;
    });
  };
  const handleotpsignuplogin = (e) => {
    e.preventDefault();
    const otpsignupString = otpsignup.join("");
    var formdata = new FormData();

    formdata.append("mobile_no","+1" + phoneNumber);
    
    formdata.append("otp", +otpsignupString);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    fetch(apiurl + "/api/verifyOtp", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
      result.message ===
          '["error","Your otpsignup has been expired"]'
        ) {
          toast.error("Your One Time Passcode has been expired", {
            autoClose: 1000,
          });
        } else if (
        (result.message) ==="Invalid OTP"
        ) {
          toast.error("Your OTP is not correct", { autoClose: 1000 });
        } else {
          // Setcode("")
          toast.success("User Login successfully", { autoClose: 1000 });
          console.log(result.data.id,result.data,'data')
          localStorage.setItem("vacayid", result.data.id);
          localStorage.setItem(
            "namevacay",
            result.data.fname + " " + result.data.lname
          );
          navigate("/");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const hnadleInputChangesubmit = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "firstName" || name === "lastName") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Alphabets are allowed" });
        return;
        setFirstName(value);
      }
    }
    if (name === "phoneNumber") {
      if (!/^[+\d\s]*$/.test(value)) {
        setErrors({ ...errors, [name]: "Only Numbers are allowed" });
        return;
      }

      if (value.length > 13) {
        setErrors({
          ...errors,
          [name]: "Maximum 13 digit Phone no is allowed",
        });
        return;
      }
    }

    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "phoneNumber") {
      setPhoneNumber(value);
    } else if (name === "email") {
      setEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (agrement === "") {
      newErrors.agrement = "Please accept terms and conditions";
    }
    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = "Mobile Number is required";
    } else if (phoneNumber.trim().length < 10) {
      newErrors.phoneNumber = "Mobile Number should be at least 10 digits";
    }

    if (email) {
      const emailRegex = /^[a-zA-Z0-9.]+@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$/;

      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter valid email address.";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (
      firstName !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      phoneNumber.length >= 10 &&
      agrement != ""
    ) {
      Setloader(true);
      var formdata = new FormData();
      formdata.append("fname", firstName);
      formdata.append("lname", lastName);
      formdata.append("mobile_no", "+1" + phoneNumber);
      formdata.append("email", email);
      formdata.append("tcc", agrement);
      formdata.append("type","registration")
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(apiurl + "/api/sendOtp", requestOptions)
        .then((response) => {
          if (!response.ok) {
          }
          return response.json();
        })
        .then((result) => {
       
          if (result.message === "This mobile no is already registered.") {
            toast.error("The mobile no has already been taken.", {
              autoClose: 3000,
            });
            Setloader(false);
          } else if (
            result.message === "The mobile no field must be 10 digits."
          ) {
            toast.error("The mobile no field must be 10 digits.", {
              autoClose: 3000,
            });
            Setloader(false);
          } else if (
            result.message ===
            "Please provide a valid mobile number from the USA."
          ) {
            toast.error("Please provide a valid mobile number from the USA.", {
              autoClose: 3000,
            });
            Setloader(false);
          } else if (result.message === "The email has already been taken.") {
            toast.error("The email has already been taken.", {
              autoClose: 3000,
            });
            Setloader(false);
          }

          if (result.message === "OTP sent successfully") {
            // localStorage.setItem("vacayid", result.customer.id);

            // localStorage.setItem(
            //   "namevacay",
            //   result.customer.fname + " " + result.customer.lname
            // );

            Setloader(false);
            Setcode(true);
            SetUserid(result.data.id);
            // navigate("/");
            toast.success("User Signup Succesfully", { autoClose: 1000 });
          }
        })
        .catch((error) => {
          Setloader(false);
        });
    } else {
    }
  };
  const Modalclosesignup = () => {
    setShowModal(false);
    navigate("/");
  };
  const handleInputClick = (name) => {
    setErrors({ ...errors, [name]: "" });
  };

  return (
    <>
      <div className="signup-container">
        {showModal && (
          <div className="custom-modal">
            {loader && (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            )}
            {code != false ? (
              <>
                <div className="modal-header">
                  <h3 className="modal-title">Verify One Time Passcode</h3>
                  <button
                    type="button"
                    className="close signupotpclose"
                    onClick={Modalclosesignup}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="fromvacaysl">
                    <div className="form-group">
                      <label>Verify Your One Time Passcode</label>
                      <div className="otpsignup-input-container">
                        {otpsignup.map((value, index) => (
                          <input
                            key={index}
                            // className="otpsignup-button"
                            maxLength={1}
                            value={value}
                            onChange={(e) => handleotpsignupChange(index, e)}
                            ref={refs[index]}
                          />
                        ))}
                      </div>
                      {/* Display error message if any */}
                      {errors.otpsignup && (
                        <span className="error-message">
                          {errors.otpsignup}
                        </span>
                      )}
                    </div>

                    <button onClick={handleotpsignuplogin}>Login</button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <div className="modal-header">
                  <h1 className="modal-title">Sign Up</h1>
                  <button
                    type="button"
                    className="close"
                    onClick={Modalclosesignup}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="fromvacaysl">
                    <div className="form-group">
                      <label>
                        First Name <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        name="firstName"
                        onChange={hnadleInputChangesubmit}
                        onClick={() => handleInputClick("firstName")}
                        className={errors.firstName ? "error2" : ""}
                      />
                      {errors.firstName && (
                        <span className="error-message">
                          {errors.firstName}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Last Name <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        name="lastName"
                        onChange={hnadleInputChangesubmit}
                        onClick={() => handleInputClick("lastName")}
                        className={errors.lastName ? "error2" : ""}
                      />
                      {errors.lastName && (
                        <span className="error-message">{errors.lastName}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        {" "}
                        Mobile Number <span className="error">*</span>
                      </label>
                      <div className="input-container">
                        <span className="prefix">+1</span>
                        <input
                          type="text"
                          placeholder="Mobile Number"
                          value={phoneNumber}
                          onChange={hnadleInputChangesubmit}
                          onClick={() => handleInputClick("phoneNumber")}
                          name="phoneNumber"
                          className={errors.phoneNumber ? "error2" : ""}
                        />
                      </div>
                      <div className="error-mobile">
                        {errors.phoneNumber && (
                          <span className="error-message">
                            {errors.phoneNumber}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Email address (optional)"
                        value={email}
                        onChange={hnadleInputChangesubmit}
                        onClick={() => handleInputClick("email")}
                        name="email"
                      />

                      {errors.email && (
                        <span className="error-message">{errors.email}</span>
                      )}
                    </div>

                    <input
                      type="checkbox"
                      className="checkboxterms"
                      checked={agrement}
                      onClick={() => {
                        setagrement("yes");
                        setErrors((prevData) => ({
                          ...prevData,
                          agrement: "",
                        }));
                      }}
                    />
                    <label>
                      {" "}
                      I accept the{" "}
                      <span class="signupconditions">
                        {" "}
                        <a href="/term-and-conditions" target="_blank">
                          terms and conditions
                        </a>{" "}
                        of VacayDrop
                      </span>
                    </label>
                    {errors.agrement != "" && (
                      <div className="error-message">{errors.agrement}</div>
                    )}
                    <h5>
                      Already a customer <Link to="/login">Login</Link>
                    </h5>
                    <button onClick={handleSubmit}>Sign Up</button>
                  </form>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Signup;
